import React, { Fragment } from "react";
import { Logo } from "@/data";
import { Link } from "gatsby";
import { Col, Row } from "react-bootstrap";
import {
  FooterAboutData,
  FooterLinksData,
  FooterContactData,
} from "@/data";

const Footer = () => {
  return (
    <Fragment>
      <footer className="footer">
        <div className="thm-container">
          <Row>
            <Col lg={4}>
              <img
                src={Logo.light}
                style={{ width: "200px" }}
                alt="logo"
              />
              <div className="footer-widget about-widget">
                <p>{FooterAboutData.text}</p>
              </div>
            </Col>
            <Col lg={4}>
              <div className="footer-widget links-widget explore">
                <div className="title">
                  <h3>{FooterLinksData.title}</h3>
                </div>
                <ul className="link-list">
                  {FooterLinksData.links.map(({ url, label }, index) => {
                    return (
                      <li key={index}>
                        <Link to={url}>{label}</Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </Col>
            <Col lg={4}>
              <div className="footer-widget contact-widget">
                <div className="title">
                  <h3>{FooterContactData.title}</h3>
                </div>
                {FooterContactData.infos.map(({ text, url }, index) => {
                  return (
                    <p key={index}>
                      <a href={url}>{text}</a>
                    </p>
                  );
                })}
              </div>
            </Col>
          </Row>
        </div>
      </footer>
      <div className="footer-bottom">
        <div className="thm-container clearfix">
          <div className="float-left copy-text">
            <p>
              &copy; Savvy {new Date().getFullYear()} Desarrollado por {" "}
              <a href="http://diamondsoft.com.mx/" target="_blank" rel="noreferrer">
                Diamond Soft  <i className="fa fa-gem"></i>
              </a>
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Footer;
